// Import Custom Variables (Overrides Default Bootstrap Variables)
@import "variables.scss";

// Import Bootstrap
@import '~bootstrap/scss/bootstrap.scss';

// Import Custom Mixins and Components
@import "mixins.scss";
@import "global.scss";
@import "utilities.scss";

// Uppy file uploader
@import "~@uppy/core/dist/style.css";
@import "~@uppy/dashboard/dist/style.css";

// Custom Components
@import "dropdowns.scss";
@import "navs.scss";
@import "buttons.scss";
@import "cards.scss";
@import "charts.scss";
@import "login.scss";
@import "error.scss";
@import "footer.scss";


.iframe-background {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    z-index:1000;
    width: 100vw;
    height:100vh;
    background-color: #000000ad;


    .iframe-wrapper {
        align-content: center;
        align-items: center;
        vertical-align: center;
        margin: auto;

        iframe {
            margin: auto;
            width: 100vw;
            height:100vh;
            border: none;
        }
    }

    button {
        width: 120px;
        position: absolute;
        margin: auto;
        left: calc( 50% - 60px );
        top: calc( 50% - 340px )
    }

}
